// Override default variables before the import

////////////////////////////////////////////////////////////////////////// Fonts Start /////////////////////////////////////////////////////////////////////////////////////////// 
@font-face {font-family: 'Sohnebreit';src: url('../../public/assets/fonts/SöhneBreit/SöhneBreit-Dreiviertelfett.otf');}
@font-face {font-family: 'Sohne';src: url('../../public/assets/fonts/Sohne/Söhne-Buch.otf');}
@font-face {font-family: 'Sohne-Halbfett';src: url('../../public/assets/fonts/Sohne/Söhne-Halbfett.otf');}
@font-face {font-family: 'SohneBreit-Kraftig';src: url('../../public/assets/fonts/SöhneBreit/SöhneBreit-Kräftig.otf');}
@font-face {font-family: 'SohneBreit-Halbfett';src: url('../../public/assets/fonts/SöhneBreit/SöhneBreit-Halbfett.otf');}
@font-face {font-family: 'SohneBreit-Fett';src: url('../../public/assets/fonts/SöhneBreit/SöhneBreit-Fett.otf');}
@font-face {font-family: 'SohneBreit-Extrafett';src: url('../../public/assets/fonts/SöhneBreit/SöhneBreit-Extrafett.otf');}
/// Old Fonts ///
@font-face {font-family: 'Inter';src: url('./fonts/Inter-Light.ttf');src: url('./fonts/Inter-Light.ttf') format('truetype');font-weight: 300;}
@font-face {font-family: 'Inter';src: url('./fonts/Inter-Regular.ttf');src: url('./fonts/Inter-Regular.ttf') format('truetype');font-weight: 400;}
@font-face {font-family: 'Inter';src: url('./fonts/Inter-Medium.ttf');src: url('./fonts/Inter-Medium.ttf') format('truetype');font-weight: 500;}
@font-face {font-family: 'Inter';src: url('./fonts/Inter-SemiBold.ttf');src: url('./fonts/Inter-SemiBold.ttf') format('truetype');font-weight: 600;}
@font-face {font-family: 'Inter';src: url('./fonts/Inter-Bold.ttf');src: url('./fonts/Inter-Bold.ttf') format('truetype');font-weight: 700;}
@font-face {font-family: 'Rubik-Bold';src: url('./fonts/Rubik-Bold.ttf');src: url('./fonts/Rubik-Bold.ttf') format('truetype');font-weight: 700;}
@font-face {font-family: 'Rubik-Semi-bold';src: url('./fonts/Rubik-SemiBold.ttf');src: url('./fonts/Rubik-SemiBold.ttf') format('truetype');font-weight: 600;}
@font-face {font-family: 'Rubik';src: url('./fonts/Rubik-Regular.ttf');src: url('./fonts/Rubik-Regular.ttf') format('truetype');font-weight: 400;}

////////////////////////////////////////////////////////////////////////// Fonts End /////////////////////////////////////////////////////////////////////////////////////////// 
////////////////////////////////////////////////////////////////////////// Variables Start ///////////////////////////////////////////////////////////////////////////////////////////
$body-bg: #F7F5ED;
$body-color: #FFFFFF;
$navbar-light-color: #FFFFFF;
$navbar-light-hover-color: #FF4221;
$font-family-sans-serif: 'Inter', sans-serif;
$font-family-base: $font-family-sans-serif !default;
$primary: #F1A83E;
$primary: $primary !default;
$primary-withOpacity: #FF422180;
$primaryWhite: #ffffff;
$primaryBlack: #0E0E0E;
$black: $primaryBlack !default;
$headerBackgroundColor: #F7F5ED;
$container-max-size: 1400px;
$divider-color: #353535;
$divider-secondary-color: #464646;
$home-head-text-color: #ffffff;
$placeholder-color: #cccccc;
$search-icon-bg-color: rgba(255, 66, 33, 0.15);
$head-font-family: 'Rubik-Semi-bold';
$title-font-family: 'Rubik';
$about-hero-bg-color: #080808;
$about-wallet-bg-color: #111111;
$about-border-color: #FF422130;
$table-tabs-border-color: #515151;
$table-td-border-color: #232323;
$primaryWhite-withOpacity: #ffffff80;
$primaryBlack-withOpacity: #00000050;
$global-border-color: #CCCCCC;
$blog-box-bg-color: #111111;
$price-card-bg-color: #181818;
$price-card-title-color: #EEEEEE;
$mobile-menu-bg-color: #2f8ba847;
$color-one:#726E68;
$color-two:#CF87E6;
$color-three:#F1A83E;

$global-gradient-color: linear-gradient(90deg, #20DF76 0%, #00D9F5 100%);
$global-gradient-hover-color: linear-gradient(90deg, #00D9F5 0%, #20DF76 100%);

$bar-gradient-color: #CF87E6;

/// New Fonts ///
$font-family-Sohne: 'sohne', sans-serif;
$font-family-Sohnebreit: 'sohnebreit', sans-serif;
$font-family-SohneBreit-Kraftig: 'SohneBreit-Kraftig', sans-serif;
$font-family-SohneBreit-Halbfett: 'SohneBreit-Halbfett', sans-serif;
$font-family-Sohne-Halbfett: 'Sohne-Halbfett', sans-serif;
$font-family-Sohne-Fett: 'SohneBreit-Fett', sans-serif;
$font-family-Sohne-Extrafett: 'SohneBreit-Extrafett', sans-serif;

/////////////////////////////////////////////////////////////////////////////// Variables End ////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////// Mixin Start ///////////////////////////////////////////////////////////////////
@mixin body{font-family:$font-family-Sohne;}
@mixin global-btn ($bg-color: $primary, $text-color: $primaryWhite,$border-width: 1px, $border-style: solid, $border-color: $primary,$font-size: 0.875rem, $font-weight: 400, $line-height: 1.625rem, $text-case: uppercase, $p-spacing: 7px 30px,$btn-transition: all .1s linear,$btn-line: none,$btn-display: inline-block,$mt-spacing: 0, $ltr-spacing: 0.1em) {
    background: $bg-color;color: $text-color;border: $border-width $border-style $border-color;font-size: $font-size;font-weight: $font-weight;line-height: $line-height;text-transform: $text-case;padding: $p-spacing;transition: $btn-transition;text-decoration: $btn-line;display: $btn-display;margin-top: $mt-spacing; letter-spacing: $ltr-spacing;
    &:hover {background: transparent;}
}
@mixin menu-link ($menu-fs: 1rem, $menu-ls: 1.25rem, $menu-mspacing: 0 1.438rem, $menu-pspacing: unset, $menu-fcase: capitalize, $menu-ltrspacing: 0) {
    font-size: $menu-fs;line-height: $menu-ls;margin: $menu-mspacing;padding: $menu-pspacing; text-transform: $menu-fcase; letter-spacing: $menu-ltrspacing;
}
@mixin divider ($width: 100%, $height: 0, $border-width: 2px, $border-style: solid, $border-color: $divider-color, $m-spacing: 0 ) {
    border-width: $border-width;border-style: $border-style;border-color: $border-color;width: $width;height: $height;margin: $m-spacing;
    @media (max-width: 767px) {
        border-width: 1px;
    }
}
@mixin progress($bar-bg: #CF87E6, $bar-radius: 5.208vw, $bar-mb-spacing: 1.771vw) {
    background: $bar-bg;border-radius: $bar-radius; margin-bottom: $bar-mb-spacing;
}
///////////////////////////////////////////////////// Mixin End  ///////////////////////////////////////////////////////////////////