// If you want to override variables do it here
@import "variables";

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import 'aos/dist/aos.css';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

*, body {scroll-behavior: smooth;padding: 0;margin: 0;box-sizing: border-box;}
body{font-family:$font-family-Sohne;}
.container {width: 100% !important;position: relative;}
.container-fluid{max-width: 100% !important;}
.no-padding {
    padding: 0;
}

.vertical-divider{@include divider ($width: 0, $height: 100%);}
.horizontal-divider{@include divider ($width: 100%, $height: 0);}
.error_layout_main {
    .err-img-col {
        padding: 0 0 6.25rem;
        .err-img {max-width: 600px;margin: 3rem auto 5rem;display: block;}
        .err-link {@include global-btn}
    }
}
.header-main-sec{
    .container-fluid{
        padding: 0 2.188vw;
    }
}

.esg-legal {
    margin-top: 32px;
    color: #726E68;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 17.08px */
}

.testimonial-legal {
    margin-top: 6px;
    color: #726E68;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 17.08px */
}

.email-wall {
    color: #13166C;
    font-family:$font-family-Sohne;
    z-index: 10;
    position: absolute;
    .email-wall-head {
        font-weight: 600;
        font-size: 27px;
    }
    form {
        width: 425px;
    }
}

.filter {
    filter: blur(8px) grayscale(80%) opacity(25%);
}

// HomePage Css Start
.home-hero-sec {
    position: relative;
    padding-top:6.771vw;
    background-size: cover;
    background-position: top center;
    background-attachment: fixed;
    scroll-behavior: smooth;
    .home-search-img {
        text-align: center;
        margin-bottom: 1.5vw;
        img {
            width: 100%;
            max-width: 21.510vw;
        }
    }
    .top-hero-sec{
        .change-btn {
            color: #13166C;
            font-size: 1.042vw;
            font-weight: 700;
            line-height: 15px;
            margin-bottom: 2.917vw;
            text-decoration: none;
            display: flex;
            align-items: center;
            font-family:$font-family-Sohne;
            img{
                margin-right: 0.278vw
            }
        }
        h2{
            color: #13166C;
            font-family:$font-family-Sohne;
            font-size: 4vw;
            font-weight: 900;
            letter-spacing: 0vw;
            max-width: 60vw;
            line-height: 100%;
        }
        p{
            color: #726E68;
            font-size: 0.625vw;
            line-height: 0.729vw;
            text-align: right;
            border-bottom: 1px solid #DCD2C3;
            padding-bottom: 0.365vw;
            margin-bottom: 0;
            font-family:$font-family-Sohne;
            a{
                text-decoration: underline;
                color: #726E68;
            }
        }
    }
    .public-stats-left-main {
        img{
            max-width: 33.021vw;
        }
        .public-stats-head {
            color: #CF87E6;
            text-align: left;
            font-size: 6.406vw;
            max-width: 25vw;
            font-family:$font-family-Sohnebreit;
            line-height: 6.432vw;
            margin-top: 3.125vw;
            margin-bottom: -3px;

            .number-formates{
                .letters {
                margin-left: -5px;
            }
        }
        }
        .public-stats-pera{
            color: #726E68;
            font-size: 1.12vw;
            line-height: 1.25;
            max-width: 100%;
            font-family:$font-family-Sohne;
            margin-bottom: 25px;
            // margin-left: 4px;
        }
    }
    .home-hero-main-head {font-family: $head-font-family;font-size: 2.813rem;line-height: 100%;color: $home-head-text-color;margin-bottom: 0;}
    .home-img-row {
        margin-top: 5rem;
        .step-form-main {
            .step-form-ryt {
                position: relative;
                transform: translateX(-6%);
                padding: 1.823vw;
                background: #272727;
                border-radius: 1.042vw;
                .step-form-head {
                    font-weight: 700;
                    font-size: 1.042vw;
                    line-height: 115%;
                    color: #FFFFFF;
                    margin-bottom: 1.146vw;
                }
                .progress {
                    @include progress;
                    .progress-bar {
                        @include progress($bar-bg: $global-gradient-color, $bar-mb-spacing: 0);
                    }
                }
                .form-desc {
                    font-weight: 400;
                    font-size: 0.833vw;
                    line-height: 135%;
                    color: #FFFFFF;
                    margin: 0;
                }
                .cstm-form {
                    margin-top: 0.625vw;
                    .cstm-formgroup {
                        display: flex;
                        gap: 2.083vw;
                        border: 1px solid #fff;
                        border-right: 0;
                        border-left: 0;
                        padding: 0.469vw 0.260vw;
                        align-items: center;
                        &:first-child {border-bottom: 0;}
                    }
                    .cstm-formlabel {
                    width: 25%;
                    margin: 0;
                    }
                    .form-control {
                        width: 70%;
                        border: 0;
                        background: transparent;
                    }
                    .cstm-form-btn {
                        font-weight: 400;
                        font-size: 1.146vw;
                        line-height: 1.146vw;
                        text-align: center;
                        color: #000000;
                        padding: 0.625vw 4.167vw;
                        margin-top: 3.646vw;
                        background: $global-gradient-color;
                        &:hover {background: $global-gradient-hover-color;}
                        border-radius: 5.208vw;
                        border: 0;
                    }
                }
            }
            .step-form-lft {
                position: relative;
                transform: translateX(6%);
                z-index: -1;
                width: 100%;
                height: 100%;
                img {
                    border-radius: 1.042vw;
                    width: 100%; height: 100%;
                    margin-top: 0.02rem;
                }
            }
        }
    }
    .select__option{
        color:#F1A83E;
    }
    .basic-single{
        max-width: 25.938vw !important;
        width: auto;
        margin: auto;
    }
}
// Counter Box Start
.counter-box-col {
    padding: 0;
    padding-right: 14.7px;
}

.counter-box-col:last-child {
    padding-right: 0;
}
.counter-box {
    background: #F1EBE1;
    border-radius: 1.042vw;
    padding: 1.302vw 1.302vw 0.521vw;
    position: relative;
    min-height: 13.102vw;
    margin-bottom: 0.938vw;

    .tooltiptext {
        visibility: hidden;
        max-width: 12vw;
        min-height: 5vw;
        background-color: #524F4B;
        color: #fff;
        text-align: left;
        border-radius: 0.608vw;
        padding: 0.694vw 1.694vw 0.694vw 0.694vw;
        position: absolute;
        top: -50%;
        right: -50%;
        transform: translate(-50%, 45%);
        font-size: 0.9vw;
        line-height: 0.833vw;
        font-family: "Sohne";
        letter-spacing: 0.6px;
        z-index: 1;
        button.tooltip-close {
            background: no-repeat;
            box-shadow: none;
            border: none;
            position: absolute;
            right: 1vw;
            top: 0.5vw;
            svg {
                width: 0.694vw;
                height: 0.694vw;
            }
        }
    }
    .tooltip-active span.tooltiptext {
        visibility: visible !important;
    }
    .q-mark {
        position: absolute;
        right: 1rem;
        top: 1rem;
    }
    .counter-box-head {
        font-size: 1.6vw;
        line-height: 100%;
        color: #13166C;
        margin-bottom: 0.781vw;
        font-family:$font-family-SohneBreit-Halbfett;
    }
    .fa-question-circle {
        position: absolute;
        top: 0.521vw;
        right: 0.781vw;
    }
    .counter-img-main {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 5px;
        .progress {
            background: #13166C;
            width: 100%;
            overflow: inherit;
            height: 2px;
            align-items: center;
            margin: 1.042vw 0;
            .progress-bar {
                height: 0.729vw;
                margin-bottom: 0;
                @include progress($bar-bg: $bar-gradient-color, $bar-mb-spacing: 0);
            }
        }
    }
    .industry-box{
        p{
            color: #726E68;
            font-size: 0.625vw;
            line-height: 0.729vw;
            max-width: 2.708vw;
            font-family:$font-family-Sohne;
        }
    }
    .employees_counter_box{
        gap: 1px;
    }
    .factory-image-counter {
        padding: 0px !important;
}
    .counter-box-para {
        font-weight: 400;
        font-size: 1.042vw;
        line-height: 1.250vw;
        color: #726E68;
        max-width: 100%;
        min-height: 3.888vw;
        margin: 0;
        font-family:$font-family-Sohne;
    }

}
// Compared Other Start
.compared-other-sec{
    .public-stats-main{
        background-color: #F1EBE1;
        border-radius: 1.042vw;
        padding: 1.458vw;
        .public-stats-logos{
            ul{
                list-style: none;
                padding-left: 0;
                li{
                    margin: 0;
                    height: 60px;
                    display: flex;
                    img{
                        max-width: 100%;
                    }
                }
            }
        }
        .public-stats-head{
            color: #13166C;
            font-size: 1.4vw;
            font-family: $font-family-Sohne-Halbfett;
        }
        .public-stats-pera{
            font-size: 0.938vw;
            line-height: 1.30vw;
            color: #726E68;
        }
        .public-stats-progress-main{
            margin-top: 1.667vw;
            .public-stats-progress{
                display: flex;
                align-items: center;
                margin: 0;
                height: 60px;
                &:last-child{
                    margin-bottom: 0;
                }
                .progress{
                    max-width: 100%;
                    background: #DDD2C1;
                    width: 100%;
                    overflow: inherit;
                    height: 2px;
                    align-items: center;
                    margin: 0;
                    .progress-bar{
                        height: 1.229vw;
                        margin-bottom: 0;
                        background: #CF87E6;
                        border-radius: 5.208vw;
                        margin-bottom: 0;
                    }
                }
                span{
                    min-width: 8%;
                    background: #f1ebe1;
                    &:last-child{
                        color: #13166C;
                        font-size: 0.833vw;
                        line-height: 0.833vw;
                        min-width:8%;
                        padding: 0 0 0 0.781vw;
                    }
                    img{
                        max-width:100%;
                    }
                }
            }
        }
        .public-stats-right-progress {
            margin-bottom: 1.042vw;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .progress-bar-image {
                max-width: 3.646vw;
                min-width: 5vw;
                margin-right: 1.302vw;
                color: #13166C;
                font-size: 16px;
                line-height: 1.2;
                font-family: "Sohne-Halbfett", sans-serif;
            }
             img {
                width: auto;
                max-width: 4.646vw;
                max-height: 2.802vw;
            }
            .progress {
                @include progress($bar-mb-spacing: 0, $bar-bg: transparent);
                min-height: 1.354vw;
                gap: 0.625vw;
                justify-content: space-between;
                width: 100%;
                border-radius: 0;
                align-items: center;
                .progress-bar-sec {
                    width: 100%;
                    background-color: transparent;
                    height: 0.521vw;
                    border-radius: 5.208vw;
                    position: relative;
                    z-index: 0;
                    &::before{
                        content: '';
                        background-color: #DCD2C3;
                        position: absolute;
                        height: 1px;
                        width: 100%;
                        top: 50%;
                        z-index: -1;
                    }
                .progress-bar {
                    @include progress($bar-mb-spacing: 0);
                    text-align: left;
                    padding: 0 0.625vw;
                    max-width: 100%;
                    height: 0.521vw;
                    color: #13166C;
                    overflow: inherit;
                }
            }
                span {
                    align-self: center;
                    color: #13166C;
                    font-size: 16px;
                    line-height: 0.833vw;
                    min-width: 7.5vw;
                    padding: 0 0 0 0.781vw;
                    font-family: $font-family-SohneBreit-Kraftig;
                }
            }
        }
    }
}
// Slider Start
.slider-sec{
    margin-top: 1.042vw;
    .single-slide{
        background: #f2ebe1;
        border-radius: 1.042vw;
        position: relative;
        min-height: 30vw;
        .top_btn{
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            padding: 1.563vw 1.563vw 0;
            margin-bottom: 1.042vw;
            h3{
                font-size: 1.4vw;
                line-height: 1.045vw;
                color: #13166C;
                font-family: $font-family-Sohne-Halbfett;
            }
            a{
                font-size: 0.625vw;
                line-height: 0.625vw;
                text-decoration: underline;
                color: #13166C;
                font-family: $font-family-Sohne-Halbfett;
                &:hover{
                    cursor: pointer;
                }
            }
        }
        .inner-content{
            padding: 0 1.563vw;
            h5{
                font-size: 6.979vw;
                line-height: 5.833vw;
                letter-spacing: -0.2px;
                text-transform: uppercase;
                color: #CF87E6;
                display: flex;
                align-items: end;
                line-height: 0.8;
                font-family:$font-family-Sohnebreit;
                span{
                    font-size: 1.042vw;
                    line-height: 1.182vw;
                    color: #CF87E6;
                    max-width: 10.938vw;
                    text-transform: lowercase;
                    margin-left: 1.042vw;
                    font-family:$font-family-Sohne-Halbfett;
                }
            }
        }
        .equation-content{
            background: #DCD2C3;
            border-radius: 1.042vw;
            max-width: 20vw;
            padding:1.458vw;
            position: absolute;
            top: 0;
            right: -1px;
            bottom: 0;
            z-index: 2;
            display: none;
            .euation-inner-content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                position: relative;
            }
            h6{
                font-size: 1.042vw;
                line-height: 1.042vw;
                color: #13166C;
                font-family:$font-family-Sohne-Halbfett;
            }
            p{
                font-size: 0.833vw;
                line-height: 0.972vw;
                color: #726E68;
                margin-bottom: 1.042vw;
            }
            .equation-close{
                  position: absolute;
                    top: 0;
                    right: 0.521vw;
                &:hover{
                    cursor: pointer;
                }
                .eq-close{
                    position: relative;
                    &:before{
                        position: absolute;
                        left: 0.781vw;
                        content: ' ';
                        height: 0.677vw;
                        width: 2px;
                        background-color: #13166C;
                        transform: rotate(45deg);
                    }
                    &:after {
                        position: absolute;
                        left: 0.781vw;
                        content: ' ';
                        height: 0.677vw;
                        width: 2px;
                        background-color: #13166C;
                        transform: rotate(-45deg);
                    }
                }
            }
            $background-color_1: #13166C;

            .customeEquationContentBox {
                max-height: 21.6vw;
                overflow-y: scroll;
                padding-right: 0.6vw;
                &::-webkit-scrollbar {
                    width: 0.25vw;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: $background-color_1;
                }
            }
            .content-box{
                display: flex;
                margin-bottom: 0.521vw;
                justify-content: space-between;
                align-items: flex-start;
                p{
                    font-size: 0.833vw;
                    line-height: 0.833vw;
                    color: #C4B49C;
                    text-transform: uppercase;
                    max-width: 0.781vw;
                    min-width: 0.781vw;
                    font-family:$font-family-Sohne-Halbfett;
                }
                h5{
                    font-size: 0.833vw;
                    line-height: 0.833vw;
                    color: #13166C;
                    margin: 0 0.521vw;
                    max-width: 8.073vw;
                    min-width: 8.073vw;
                    font-family:$font-family-Sohne-Halbfett;
                    span{
                        font-size: 0.833vw;
                        line-height: 0.833vw;
                        color: #726E68;
                        opacity: 0.5;
                        display: block;
                        margin-top: 3px;
                        font-family:$font-family-Sohne-Halbfett;
                    }
                }
                span{
                    font-size: 0.833vw;
                    line-height: 0.833vw;
                    color: #CF87E6;
                    max-width: 3.385vw;
                    min-width: 3.385vw;
                    font-family:$font-family-Sohne-Halbfett;
                }

                .tooltip-cont {
                    position: relative;
                    img {
                        position: relative;
                        width: 1vw;
                        height: auto;
                        &:hover{
                            cursor: pointer;
                        }
                    }

                    .tooltiptext {
                        visibility: hidden;
                        min-width: 12vw;
                        background-color: #524F4B;
                        color: #fff;
                        text-align: left;
                        border-radius: 0.208vw;
                        padding: 0.694vw 1.694vw 0.694vw 0.694vw;
                        position: absolute;
                        top: 0;
                        left: -11vw;
                        font-size: 0.694vw;
                        line-height: 0.833vw;
                        z-index: 0;
                        font-family: "Sohne";
                        letter-spacing: 0.6px;

                        button.tooltip-close {
                            background: no-repeat;
                            box-shadow: none;
                            border: none;
                            position: absolute;
                            right: 1vw;
                            top: 0.5vw;
                            svg {
                                width: 0.694vw;
                                height: 0.694vw;
                            }
                        }

                    }

                }
                .tooltip-active span.tooltiptext {
                    visibility: visible !important;
                }

            }
            .total-cal{
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                border-top: 1px solid #726E68;
                padding-top: 0.625vw;
                h6{
                    font-size: 0.625vw;
                    line-height: 0.625vw;
                    color: #13166C;
                    margin-bottom: 0;
                    font-family:$font-family-Sohne-Halbfett;
                }
                p{
                    font-size: 0.625vw;
                    line-height: 0.625vw;
                    color: #CF87E6;
                    margin-bottom: 0;
                    max-width: 3.385vw;
                    min-width: 3.385vw;
                    font-family:$font-family-Sohne-Halbfett;
                }
            }
        }
        .eq-box-open{
            display: block;
        }
        img{
            width: 100%;
            position: absolute;
            bottom: 0;
            max-width: 100%;
            border-bottom-left-radius: 1.042vw;
            border-bottom-right-radius: 1.042vw;
        }
    }
    .slick-slider{
        .slick-arrow {
            z-index: 1;
            &.slick-next{
                right: -1.823vw;
                &::before{
                    color: #13166C;
                    transform: rotate(135deg);
                }
            }
            &.slick-prev{
                left: -1.823vw;
                &::before{
                    color: #13166C;
                    transform: rotate(-45deg);
                }
            }
            &:before{
                content: "";
                display: block;
                margin: 1.563vw auto;
                width: 1.042vw;
                height: 1.042vw;
                border-top: 3px solid #13166C;
                border-left: 3px solid #13166C;
            }
        }
    }
}
// Home Content Start
.home-content-sec{
    margin: 3.333vw 0 0;
    .inner-content{
        max-width: 38.594vw;
        h3{
            font-size: 1.4vw;
            line-height: 1.063vw;
            color: #13166C;
            margin-bottom: 0.781vw;
            font-family:$font-family-Sohne-Halbfett;
        }
        p{
            font-size: 1.12vw;
            line-height: 1.25;
            color: #726E68;
            font-family:$font-family-Sohne;
        }
    }
}
// Line Up Start
.line-up-sec{
    background: url("../../public/assets/images/flower-medium.png");
    background-repeat: no-repeat;
    background-position: center right;
    padding-top: 4.792vw;

    .line-up-inner-container {
     padding:0;
     margin:auto;
     justify-content: center;
        .row{
            padding:0;
        }
    }
    .tooltip-active span.tooltiptext {
        visibility: visible !important;
    }
    .tooltip-cont.tooltip-active{
        position: relative;
    }
    .tooltiptext {
        visibility: hidden;
        max-width: 12vw;
        min-width: 12vw;
        background-color: #524F4B;
        color: #fff;
        text-align: left;
        border-radius: 0.208vw;
        padding: 0.694vw 1.694vw 0.694vw 0.694vw;
        position: absolute;
        top: -100%;
        transform: translate(0%, -80%);
        right: -50%;
        font-size: 0.694vw;
        line-height: 0.833vw;
        z-index: 0;
        font-family: "Sohne";
        letter-spacing: 0.6px;
        button.tooltip-close {
            background: no-repeat;
            box-shadow: none;
            border: none;
            position: absolute;
            right: 0.260vw;
            top: 0.5vw;
            svg {
                width: 0.694vw;
                height: 0.694vw;
            }
        }
    }
    .current-fund-head{
        font-size: 2.083vw;
        line-height: 1.1;
        letter-spacing: -0.02px;
        color: #13166C;
        margin-bottom: 1.250vw;
        border-bottom: 1px solid #DCD2C3;
        padding-bottom: 0.521vw;
        font-family:$font-family-Sohne-Halbfett;
        .tooltip-cont {
            width: 100%;
        }
    }
    .accordion-titles{
        .current-sub-pera{
            position: relative;
        }
        p{
            font-size: 1.042vw;           ;
            line-height: 1.1;
            color: #13166C;
            font-weight: 600;
            display: flex;
            font-family:$font-family-Sohne-Halbfett;
            .tooltip-cont {
                width: 100%;
            }
            .current-sub-para{
                max-width: 75%;
            }
            img.fa-question-circle{
                margin-left: 0.521vw;
                max-width: 0.677vw;
                max-height: 0.677vw;
                width: 100%;
            }
        }
    }
    .tab-sec-accordion{
        .accordion-item{
            border: none;
            border-bottom: 1px solid #13166C;
            border-radius: 0;
            &:last-child{
                border-bottom: 1px solid #13166C;
            }
            .row{
                margin-left: 0;
                width: 100%;
                .col-md-6{
                    padding-left: 0;
                    h6{
                        padding-left:0;
                    }
                }
                h6{
                    padding-left: 0.365vw;
                    margin-bottom: 0;
                    font-family:$font-family-Sohne;
                    line-height: 1.063vw;
                    font-size: 1.042vw;
                    font-weight: 500;
                }
            }
            .tab-sec-accordion-header {
                button.accordion-button{
                    color: #13166C;
                    padding: 0.938vw 0;
                    background-color: transparent;
                    &:not(.collapsed) {
                        &::after {
                        display: none;
                    }
                    }
                    &::after{
                        display: none;
                    }
                    .tab-accordian_head{
                        color: #13166C;
                        margin: 0;
                        line-height: 1.063vw;
                        font-size: 1.042vw;
                        font-weight: 500;
                        font-family:$font-family-Sohne;
                    }
                }
            }
            .accordion-collapse{
                .accordion-body{
                    padding: 1rem 0;
                    p{
                        color: #726E68;
                        font-family:$font-family-Sohne;
                    }
                }
            }
        }
    }
}
// How We Start
.how-we-sec{
    padding-top: 5.208vw;
    .how-head-sec{
        h2{
            font-size: 2.083vw;
            line-height: 2.052vw;
            letter-spacing: -0.02px;
            color: #13166C;
            margin-bottom: 1.563vw;
            border-bottom: 1px solid #DCD2C3;
            padding-bottom: 0.521vw;
            font-family:$font-family-Sohne-Halbfett;
        }
        h5{
            font-size: 1.4vw;
            line-height: 1.042vw;
            color: #13166C;
            margin-bottom: 0.781vw;
            font-family:$font-family-Sohne-Halbfett;
        }
        p{
            font-size: 1.12vw;
            line-height: 1.25;
            color: #726E68;
            max-width: 38.750vw;
            font-family:$font-family-Sohne;
        }
    }
    .how-bottom-sec{
        margin-top: 3.333vw;
        h5{
            font-size: 1.4vw;
            line-height: 1.042vw;
            color: #13166C;
            margin-bottom: 0.781vw;
            font-family:$font-family-Sohne-Halbfett;
        }
        .how-boxes{
            .slick-track{
                .slick-slide{
                    &:first-child .how-single-box h5{
                        color: #F1A83E;
                        &:after{
                            background-color: #F1A83E;
                        }
                    }
                    &:nth-child(2) .how-single-box h5{
                        color: #CF87E6;
                        &:after{
                            background-color: #CF87E6;
                        }
                    }
                    &:nth-child(3) .how-single-box h5{
                        color: #7FD03E;
                        &:after{
                            background-color: #7FD03E;
                        }
                    }
                    &:last-child .how-single-box h5{
                        color: #7FD03E;
                        &:after{
                            background-color: #7FD03E;
                        }
                    }


                }
            }


            .how-single-box{
                    background: #F1EBE1;
                    border-radius: 1.042vw;
                    padding: 1.5vw;
                    min-height: 22.771vw;
                    margin-right: 1.146vw;
                h5{
                    font-size: 1.198vw;
                    line-height: 1.042vw;
                    position: relative;
                    font-family:$font-family-Sohne-Halbfett;
                    &:after{
                        background-color: transparent;
                        width: 0.729vw;
                        height: 0.729vw;
                        border-radius: 2.604vw;
                        position: absolute;
                        top: 0;
                        right: 0;
                        content: "";
                    }
                }
                p{
                    font-size: 1.146vw;
                    line-height: 1.3;
                    color: #726E68;
                    margin-bottom: 0;
                    font-family:$font-family-Sohne;
                }
            }
        }
        .slick-list {
            padding-left: 0!important;
        }
    }
}
// Comparison Start
.comparison-sec{
    margin-top: 2.604vw;
    .camparison_accordion{
        border-bottom: 1px solid #13166C;
        .comparison_pera{
            font-size: 2.083vw;
            line-height: 2.052vw;
            letter-spacing: -0.02px;
            color: #13166C;
            margin-bottom: 1.563vw;
            padding-bottom: 0.521vw;
            font-family:$font-family-Sohne-Halbfett;
        }
        .comparison-table{
            .cost_head_div{
                display: flex;
                img{
                    margin-left: 0.521vw;
                    max-width: 0.677vw;
                    max-height: 0.677vw;
                    width: 100%;
                }
            }
            p{
                font-size: 1.2vw;
                line-height: 0.781vw;
                color: #13166C;
                font-family:$font-family-Sohne-Halbfett;
            }
            .good-head{
                color:#F1A83E;
            }
            .fossil-head{
                color:#CF87E6;
            }
            .vote-head{
                color:#7FD03E;
            }
            .availability-head{
                color:#419FF5;
            }
        }
        .tooltip-cont {
            position: relative;
            img {
                position: relative;
                width: 10px;
                height: auto;
                &:hover{
                    cursor: pointer;
                }
            }

            .tooltiptext {
                visibility: hidden;
                background-color: #524F4B;
                color: #fff;
                text-align: left;
                border-radius: 0.608vw;
                padding: 0.694vw 1.694vw 0.694vw 0.694vw;
                position: absolute;
                left: -25px;
                top: -75px;
                font-size: 10px;
                line-height: 1.2;
                min-width: 150px;
                max-width: 30vw;
                transform: translate(-50%, 45%);
                font-family: "Sohne";
                letter-spacing: 0.6px;
                z-index: 1;

                button.tooltip-close {
                    background: no-repeat;
                    box-shadow: none;
                    border: none;
                    position: absolute;
                    right: 1vw;
                    top: 0.5vw;
                    svg {
                        width: 0.694vw;
                        height: 0.694vw;
                    }
                }

            }

        }
        .tooltip-active span.tooltiptext {
            visibility: visible !important;
        }
        .fa-question-circle {
            position: relative;
            top: -0.521em;
            // right: 0.781vw;
        }
        .accordion-item{
            border: none;
            .accordion-button{
                padding: 1.042vw 0;
                border-top: 1px solid #13166C;
                background: transparent;
                box-shadow: none;
                &:not(.collapsed) {
                    &::after {
                        background-image:  url("../../public/assets/images/close-angle.svg");
                        transform: rotate(90deg);
                        background-size: contain;
                    }
                }
                &::after{
                    position: absolute;
                    right: 0.521vw;
                    background-image:  url("../../public/assets/images/right-angle-light.svg");
                    transform: rotate(0deg);
                    background-size: contain;
                }
                .comparison_accordion_header{
                    color: #13166C;
                    font-size: 1.4vw;
                    line-height: 1.042vw;
                    font-family:$font-family-Sohne-Halbfett;
                }
                .row{
                    margin-left: 0;
                    .col-md-4{
                        padding-left: 0;
                    }
                }
                img{
                    padding-left: 0.625vw;
                    margin-bottom: 0;
                }
            }
            .accordion-collapse{
                .accordion-body{
                    padding: 0;
                }
                .comparison_accordion_header_sub {
                    color: #7D7D7D;
                    font-size: 1.2vw;
                    line-height: 1.5vw;
                    margin-bottom: 20px;
                    font-family:$font-family-Sohne;
                }
                .accordion_body_cost_head{
                    color: #686868;
                    font-size: 1.042vw;
                    font-family:$font-family-SohneBreit-Halbfett;
                }
                .accordion_body_cost_pera{
                    color: #7D7D7D;
                    font-size: 1vw;
                    font-family:$font-family-Sohne;
                }
                .mobile-grn-arrow {
                    display: none;
                }
            }
        }
    }
}
.accordion-titles.row{
        position: sticky;
        top: 110px;
        z-index: 9;
        background: #f7f5ed;
        // padding: 10px;
        border-bottom: 1px solid #13166C;
}
// Fossil Free Start
.fossil-free-sec{
    display: block;
    margin-top: 4.167vw;
    .background-img{
        position: relative;
        background-image: url("../../public/assets/images/testimonial-bg-1.png");
        background-size: cover;
        background-position: right center;
        border-radius: 12px;
        padding: 0;
        // margin: 0 5.625vw;
        .single-slide {
            float: left;
        }
        .inner-content{
            display: flex;
            align-items: center;
            z-index: 9;

            .container{

                margin-left: 0;
                padding: 0;

                .innner-content-row.row {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    min-height: 30.694vw;
                }

                .inner-content-img.col {
                    padding: 0;
                    max-width: 34.028vw;
                    width: 100%;
                    align-self: end;
                    img {
                        width: 100%;
                    }
                }

                .inner-content-data.col {
                    padding: 0;
                    max-width: 27.292vw;
                    width: 100%;
                    margin-top: 6.042vw;
                }


        }
            h5{
                font-size: 1.736vw;
                line-height:1.736vw;
                letter-spacing: -0.2px;
                color: #13166C;
                font-weight: 400;
                margin-bottom: 1.563vw;
                font-family: "Söhne", sans-serif;
            }
            h5:before {
             content: '“';
            position: absolute;
            margin-left: -.5em;
            }
            h5:after {
                content: '”';
            }
            p{
                line-height: 0;
            .testmonial-title{
                    font-size: 1.389vw;
                    line-height: 1.111vw;
                    color: #13166C;
                    font-family: "sohne", sans-serif;
                    margin: 0;
                    font-weight: 900;
                    margin-bottom: 0.3vw;
                    display: block;
                 }
             .testmonial-para{
                font-size: 0.972vw;
                line-height: 1.111vw;
                color: #726e68;
                font-family: "sohne", sans-serif;
                margin: 0;
             }
            }
        }
        .slick-arrow {
            z-index: 1;
            &.slick-next{
                right: -1.823vw;
                width: auto;
                height: auto;
                &::before{
                    color: #13166C;
                    transform: rotate(135deg);
                }
            }
            &.slick-prev{
                left: -1.823vw;
                width: auto;
                height: auto;
                &::before{
                    color: #13166C;
                    transform: rotate(-45deg);
                }
            }
            &:before{
                content: "";
                display: block;
                margin: 1.563vw auto;
                width: 1.042vw;
                height: 1.042vw;
                border-top: 3px solid #13166C;
                border-left: 3px solid #13166C;
            }
        }
    }

}
// Graph Start
.graph-sec{
    position: relative;
    margin: 4.167vw 0 0;
    img{
        width: 100%;
        border-bottom: 1px solid #DCD2C3;
        padding-bottom: 4.167vw;
    }
    .improvedreturns{
        position: absolute;
        top: -30px;
        h2{
            font-size: 2.083vw;
            line-height: 2.052vw;
            letter-spacing: -0.02px;
            color: #13166C;
            margin-bottom: 1.563vw;
            padding-bottom: 0.521vw;
            max-width: 31.25vw;
            font-family:$font-family-Sohne-Halbfett;
        }
        .improved-label{
            position: relative;
            label{
                font-size: 1.042vw;
                line-height: 1.063vw;
                color: #13166C;
                display: flex;
                align-items: baseline;
                font-family:$font-family-Sohne-Halbfett;
                &:hover{
                    cursor: pointer;
                }
                &:before {
                    content:'';
                    -webkit-appearance: none;
                    background-color: transparent;
                    border: 2px solid #13166C;
                    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
                    padding: 0.521vw;
                    display: inline-block;
                    position: relative;
                    vertical-align: middle;
                    cursor: pointer;
                    margin-right: 0.260vw;
                }
            }
            #improvedreturns{
                display: none;
                &:checked + label:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: -0.313vw;
                    left: 0.417vw;
                    width: 0.729vw;
                    height: 1.354vw;
                    border: solid #13166C;
                    border-width: 0 4px 4px 0;
                    transform: rotate(30deg);
                }
            }
        }
        p{
    font-size: 1.12vw;
    line-height: 1.25;
            color: #726E68;
            max-width: 23.021vw;
            margin-top: 0.313vw;
            font-family:$font-family-Sohne;
        }
    }
}
// Affordable Simple Start
.affordable-simple-sec{
    margin-top: 3.125vw;
    margin-bottom:4.688vw;
    .affordable-col{
        padding-bottom: 4.688vw;
        border-bottom: 1px solid #DCD2C3;
        margin-bottom: 2.688vw;
    }

    h2 {
        font-size: 20px;
        line-height: 102%;
        letter-spacing: -0.02px;
        color: #13166C;
        margin-bottom: 18px;
        padding-bottom: 0.521vw;
        font-family: $font-family-Sohne-Halbfett;
    }
    .improvedreturns{
        .improved-label{
            position: relative;
            label{
                font-size: 1.042vw;
                line-height: 1.063vw;
                color: #13166C;
                display: flex;
                align-items: baseline;
                font-family:$font-family-Sohne-Halbfett;
                &:hover{
                    cursor: pointer;
                }
                &:before {
                    content:'';
                    -webkit-appearance: none;
                    background-color: transparent;
                    border: 2px solid #13166C;
                    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
                    padding: 0.521vw;
                    display: inline-block;
                    position: relative;
                    vertical-align: middle;
                    cursor: pointer;
                    margin-right: 0.260vw;
                }
            }
            #affordable{
                display: none;
                &:checked + label:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: -0.313vw;
                    left: 0.417vw;
                    width: 0.729vw;
                    height: 1.354vw;
                    border: solid #13166C;
                    border-width: 0 4px 4px 0;
                    transform: rotate(30deg);
                }
            }
            #simplestart{
                display: none;
                &:checked + label:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: -0.313vw;
                    left: 0.417vw;
                    width: 0.729vw;
                    height: 1.354vw;
                    border: solid #13166C;
                    border-width: 0 4px 4px 0;
                    transform: rotate(30deg);
                }
            }
        }
        p{
            font-size: 1.12vw;
            line-height: 1.25;
            color: #726E68;
            max-width: 23.438vw;
            margin-top: 0.313vw;
            font-family:$font-family-Sohne;
        }
    }
    .progress-sec{
        h5{
            font-size: 0.781vw;
            line-height: 0.781vw;
            color: #13166C;
            margin-bottom:1.042vw;
            font-family:$font-family-Sohne-Halbfett;
        }
        .images-sec{
            margin-top: 1.563vw;
            img{
                margin-right: 2.604vw;
            }
        }
        .single-progress{
            margin-bottom: 1.042vw;
            &:last-child{
                .single-progress-bar{
                    .counter-img-main{
                        .progress{
                            .progress-bar{
                                background: #CF87E6;
                            }
                        }
                    }
                }
            }
            p{
                font-size: 0.781vw;
                line-height: 0.885vw;
                color: #726E68;
                font-family:$font-family-Sohne;
            }
            .single-progress-bar{
                display: flex;
                align-items: center;
                h6{
                    font-size: 1.667vw;
                    line-height: 1.667vw;
                    color: #13166C;
                    font-family:$font-family-SohneBreit-Halbfett;
                }
                .counter-img-main{
                    margin-left: 0.260vw;
                    width: 100%;
                    .progress{
                        border-radius: 2.604vw;
                        align-items: center;
                        overflow: inherit;
                        height: 1px;
                        .progress-bar{
                            height: 0.521vw;
                            border-radius: 2.604vw;
                        }
                    }
                }
            }
        }
    }
}
// Searchable Dropdown
.search_input{
    max-width: 400px;
    margin: auto;
    position: relative;
    // input{
    //     background: #fff !important;
    //     color: #000 !important;
    // }
}
// Help Start
.help-sec{
    display: block;
    margin-top: 4.167vw;
    .background-img{
        position: relative;
        &:after{
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: linear-gradient(270deg, #CBDCC3 0%, rgba(211, 220, 195, 0.14) 90%);
        }
        img{
            width: 100%;
            position: relative;
            z-index: 9;
        }
        .inner-content{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            z-index: 9;

            .container {
                max-width: 25vw;
                margin-left: 0;
                padding: 0;
            }

            h5{
                font-size: 2.083vw;
                line-height: 2.031vw;
                letter-spacing: -0.2px;
                color: #13166C;
                margin-bottom: 1.563vw;
                font-family:$font-family-Sohne-Halbfett;
            }
            a{
                background: #F1A83E;
                border: 1px solid #F1A83E;
                font-weight: 400;
                line-height: 1.625rem;
                padding: 0.365vw 1.563vw;
                transition: all 0.3s linear;
                text-decoration: none;
                display: inline-block;
                margin-top: 0;
                letter-spacing: 0.1em;
                color: #13166C;
                font-size: 1.042vw;
                border-radius: 2.604vw;
                font-family:$font-family-Sohne-Halbfett;
                &:hover {
                    background: linear-gradient(270deg, rgba(207, 135, 230, 0.6) 0%, rgba(207, 135, 230, 0) 75.75%), #F1A83E;
                }
            }
        }
    }
}
// FAQ Start
.faq_sec{
    margin-top: 5.208vw;
    margin-bottom: 5.469vw;
    .faq_head{
        font-weight: 400;
        padding-bottom: 1.146vw;
        font-size: 2.083vw;
        line-height:2.083vw;
        color: #13166C;
        letter-spacing:-0.02px;
        font-family:$font-family-Sohne-Halbfett;
    }
    .accordion_main{
        .accordion_item{
            border: none;
            border-radius: 0;
            border-top: 2px solid #13166C;
            &:last-child{
                border-bottom: 2px solid #13166C;
            }
            .accordion_header{
                .accordion-button {
                    padding: 1.302vw 0.26vw 0 0;
                    font-weight: 400;
                    font-size: 1.042vw;
                    line-height: 100%;
                    display: flex;
                    align-items: center;
                    color: #13166C;
                    opacity:1;
                    background: transparent;
                    border: none;
                    box-shadow: none;
                    font-family:$font-family-Sohne-Halbfett;
                    &.collapsed {
                        padding: 1.302vw 0.26vw 1.302vw 0;
                    }
                    &:focus{
                        border: none;
                        box-shadow: none;
                    }
                    &::after{
                        background-image: url("../../public/assets/images/right-angle-light.svg");
                        background-size: contain;
                        transform: rotate(0deg);
                    }
                    &:not(.collapsed){
                        &::after{
                            background-image: url("../../public/assets/images/close-angle.svg");
                            transform: rotate(90deg);
                            background-size: contain;
                        }
                    }
                }

            }
        }
        .accordion-body{
            width: 75%;
            color: #726E68;
            font-size: 1.042vw;
            line-height: 1.5;
            padding-left: 0;
            font-family:$font-family-Sohne;
        }
    }
}
// Footer Start
.footer{
    padding: 2.604vw 0 ;
    .top-sec{
        .left-sec{
            display: flex;
            justify-content: center;
            img{
                max-width: 100%;
            }
        }
        .mid-sec{
            ul{
                padding-left: 0;
                list-style: none;
                display: flex;
                justify-content: space-around;
                li{
                    display: block;
                    line-height: 1;
                    h4{
                        color: #DDD2C1;
                        font-size: 0.625vw;
                        line-height: 0.729vw;
                        margin-bottom: 0.260vw;
                        font-family:$font-family-Sohne;
                    }
                    ul{
                        display: block;
                        li{
                            a{
                                font-size: 0.625vw;
                                line-height: 0.729vw;
                                color: #DDD2C1;
                                opacity: 0.75;
                                text-decoration: none;
                                font-family:$font-family-Sohne;
                            }
                        }
                    }
                }
            }
        }
        .right-sec{
            p{
                font-size: 0.625vw;
                line-height: 0.729vw;
                color: #DDD2C1;
                margin-bottom: 0.781vw;
                font-family:$font-family-Sohne;
            }
            a{
                font-size: 0.625vw;
                line-height: 0.729vw;
                color: #DDD2C1;
                display: block;
                margin-bottom: 0.781vw;
                text-decoration: none;
                font-family:$font-family-Sohne;
            }
        }
    }
    .bottom-sec{
        p{
            color: #13166C;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            font-family:$font-family-Sohne;

            a {
                color: #13166c;
            }
        }
        .bold-paragraph {
            font-weight: 600 !important;
            font-family:$font-family-Sohne-Halbfett;
        }
    }
}
.factory_img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    border-bottom-left-radius: 1.042vw;
    border-bottom-right-radius: 1.042vw;
}
.factory-image-counter{
    .counter-box-para{
        max-width: 60%;
    }
}
.relative-row {
    position: relative;
    &::after{
        position: absolute !important;
        left: 0;
    }
}
.accordion-button{
    border: none;
    box-shadow: none;
    &:focus{
        border: none;
        box-shadow: none;
    }
}
.mobile-mid-sec{
    display: none;
}

// Home Hero  Section

.home-hero-mid-txt-sec {
	border-bottom: 1px solid #13166C;
	margin-bottom: 1.563vw;
}
.home-hero-mid-txt-cont {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	padding: 6.094vw 0 2.083vw 0;
}
.home-hero-mid-txt-cont-rs {
	max-width: 38.542vw;
    margin-left: 5vw;
	p {
		color: #726E68;
		font-size: 0.938vw;
	}
}
.home-hero-mid-txt-cont-ls {
	p {
		color: #13166c;
		font-size: 1.250vw;
		font-weight: 700;
		line-height: 1.2;
	}
}
p.sample-retirement-plans-cont-p {
	color: #13166c;
	font-size: 1.25vw;
	font-weight: 700;
}
.sample-retirement-plans-cont-data {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
}
.sample-retirement-plans-cont-data-item {
	margin-bottom: 1.302vw;
	width: 23.5%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: end;
	background: #F1EBE1;
	border-radius: 0.521vw;
	min-height: 9.427vw;
	padding-bottom: 1.563vw;
	text-align: center;
	a {
		width: 100%;
		display: block;
		text-decoration: none;
         img {
            max-height: 3.281vw;
        }
	}

}
.sample-retirement-plans-cont-data-item-text {
	p {
		margin: 1.198vw 0 0 0;
		color: #726E68;
		font-size: 0.938vw;
	}
}
.sample-retirement-plans-sec {
	margin-bottom: 4.792vw;
}
.sample-retirement-plans-cont {
	padding: 0;
}

.hero-boxes{

    .row{
        padding: 0 11px;
    }
}


// HomePage Css End
@media (max-width:767px) {
    .header-main-sec {
        padding-top: 9px!important;
        }
    // Home Media Start
        .home-hero-sec {
            .home-hero-main-head{font-size: 2.25rem;line-height: 3.125rem;margin-bottom: 1rem;}
            .home-hero-main-desc {font-size: 1rem;}
            .home-img-row {margin-top: 5rem;}
        }
    // Home Media Ends
}
@media (min-width:768px) and (max-width:991px) {
    // Home Media Start
    .home-hero-sec {
        .home-hero-main-head {font-size: 3.25rem;line-height: 4.125rem;margin-bottom: 1.5rem;}
        .home-hero-main-desc {font-size: 1.125rem;line-height: 1.7rem;margin-bottom: 1.5rem;}
    }
    // Home Media Ends
}
@media (min-width:1921px){
    .container{
        max-width: 80%;
    }
}
@media (max-width:1446px){
    .slider-sec {
        .single-slide{
            min-height: 420px;
        }
    }
    .how-we-sec{
        .how-bottom-sec{
            .how-boxes{
                .how-single-box{
                    min-height: 280px;
                }
            }
        }
    }
    .graph-sec {
        .improvedreturns {
            top: 0;
        }
    }
}
@media (max-width:1024px) {
    // Hero Start
    .home-hero-sec{
        padding-top: 60px;
        .basic-single {
            max-width: 100% !important;
        }
        .home-search-img {
            img {
                max-width: 190px;
                margin-bottom: 10px;
            }
        }
        .search_input {
            padding: 0 20px;
        }
        .top-hero-sec{
            .change-btn{
                font-size: 15px;
                margin-bottom: 20px;
                img{
                    margin-right: 3px;
                }
            }
            h2{
                font-size: 40px;
                max-width: 50%;
            }
            p{
                font-size: 12px;
                padding-bottom: 10px;
            }
        }
        .public-stats-left-main {
            img{
                margin-top: 40px;
            }
            .public-stats-head{
                font-size: 98px;
                max-width: 100%;
                line-height: 1;
                word-break: break-word;
            }
            .public-stats-pera {
                font-size: 18px;
                line-height: 1.2;
                max-width: 430px;
            }
            .public-stats-small {
                font-size: 13px;
                line-height: 1;
                color: #726E68;
            }
        }
    }
    // Counter Box Start
    .hero-boxes{
        margin-bottom: 6px;
        margin-top: 32px;
        .counter-box{
            min-height: 200px;
            padding: 20px 15px 15px 20px;
            border-radius: 20px;
            margin: 0;
            .counter-box-head{
                font-size: 23px;
                margin-bottom: 10px;
            }
            .counter-box-para{
                font-size: 18px;
                line-height: 1.2;
                min-height: 48px;
                max-width: 100%;
                .tooltiptext {
                max-width: 100%;
                top: -80px;
                font-size: 10px;
                line-height: 1.2;
                padding: 10px;
                }
                .tooltip-close {
                right: 3px;
                top: 1px;
                }
                .tooltip-close svg {
                width: 7px;
                height: 7px;
                }
                }
            .industry-box {
                p{
                    font-size: 12px;
                    line-height: 1;
                    max-width: 54px;
                }
            }
        }
        .fa-question-circle {
            top: 8px;
            right: 10px;
        }
    }
    // Compared Other Start
    .compared-other-sec{
        .public-stats-main{
            padding: 20px;
            border-radius: 20px;
            .public-stats-logos ul li{
                margin: 0;
                height: 60px;
            }
            .public-stats-head {
                font-size: 20px;
                margin-bottom: 10px;
            }
            .public-stats-pera{
                font-size: 16px;
                line-height: 1.2;
                display: none;
            }
            .public-stats-progress-main {
                margin-top: 30px;
                .public-stats-progress {
                    margin:0;
                    height: 60px;
                    .progress{
                        .progress-bar{
                            height: 20px;
                        }
                    }
                    span{
                        min-width: 14%;
                        &:last-child{
                            font-size: 12px;
                            line-height: 1.2;
                            padding-left: 12px;
                            min-width: 10%;
                        }
                    }
                }
            }
            .public-stats-right-progress {
                margin:0 0 15px;
                height: auto;
                flex-direction: column;
                align-items: flex-start;
                .progress-bar-image {
                    max-width: fit-content;
                    min-width: 100px;
                    margin-bottom: 5px;
                    font-size: 18px;
                    img {
                        width: auto;
                        max-width: fit-content;
                        max-height: 30px;
                        height: 100%;
                    }
                }
                .progress {
                    height: auto;
                    .progress-bar-sec {
                        height: 10px !important;
                    .progress-bar {
                        height: 10px !important;
                        padding-left: 15px;
                    }
                }
                    span {
                        font-size: 16px;
                        line-height: 1.2;
                        padding-left: 12px;
                        min-width: 95px;
                    }
                }
            }
        }
    }
    // Slider Start
    .slider-sec{
        margin-top: 45px;
        .single-slide{
            border-radius: 12px;
            min-height: 370px;
            margin-right: 10px;
            .top_btn{
                padding: 20px 15px 0;
                margin-bottom: 20px;
                h3{
                    font-size: 20px;
                    margin-right: 10px;
                }
                a{
                    font-size: 12px;
                    min-width: fit-content;
                }
            }
            .inner-content{
                h5{
                    font-size: 64px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    span{
                        font-size: 20px;
                    color: navy;
                    line-height: 1;
                    margin-top: 15px;
                    max-width: 100%;
                    }
                }
            }
            .equation-content{
                max-width: 48%;
                padding: 10px;
                h6{
                    font-size: 16px;
                    line-height: 1;
                }
                p{
                    font-size: 12px;
                    line-height: 1.12;
                    margin-bottom: 15px;
                }
                .equation-close{
                    right: 15px;
                    .eq-close{
                        &:before{
                            height: 10px;
                            left: 8px;
                        }
                        &:after{
                            height: 10px;
                            left: 8px;
                        }
                    }
                }
                .customeEquationContentBox {
                    max-height: 100%;
                }
                .content-box{
                    margin-bottom: 8px;
                    p{
                        font-size: 11px;
                        line-height: 1;
                        max-width: 10px;
                        min-width: 10px;
                        margin-bottom: 8px;
                    }
                    h5{
                        font-size: 11px;
                        line-height: 1;
                        max-width: 140px;
                        min-width: 140px;
                        span{
                            font-size: 11px;
                            line-height: 1;
                            opacity: 0.7;
                        }
                    }
                    span{
                        font-size: 11px;
                        line-height: 1;
                        max-width: 70px;
                        min-width: 70px;
                    }
                    .tooltip-cont {
                        position: relative;
                        img {
                           width: 12px;
                        }
                        .tooltiptext {
                            font-size: 10px;
                            line-height: 1.3;
                            min-width: 150px;
                            left: auto;
                            right: 0;
                            top: 0;
                            button.tooltip-close {
                                svg {
                                    width: 7px;
                                    height: 7px;
                                }
                            }
                        }
                    }
                }
                .total-cal{
                    padding-top: 10px;
                    h6{
                        font-size: 11px;
                        line-height: 1;
                    }
                    p{
                        font-size: 11px;
                        line-height: 1;
                    }
                }
            }

        }
        .slick-list {
            padding-left: 0 !important;
        }
        .slick-slider{
            .slick-arrow{
                position: absolute;
                top: -15px;
                width: auto;

                &.slick-next{
                    right: 30px;
                    &::before{
                        width: 45px;
                        height: 20px;
                        margin: 0;
                        border: none;
                        transform: rotate(0deg) !important;
                        background-image: url('../../public/assets/images/arrows.png');
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }
                &.slick-prev{
                    left: auto;
                    right: 75px;
                    display: none !important;
                    &::before{
                        width: 45px;
                        height: 20px;
                        border: none;
                        margin: 0;
                        transform: rotate(180deg) !important;
                        background-image: url('../../public/assets/images/arrows.png');
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }

            }

        }
    }
    // Home Content Start
    .home-content-sec{
        margin: 45px 0 0;
        .inner-content{
            max-width: 80%;
            h3{
                font-size: 18px;
                line-height: 1;
                margin-bottom: 15px;
            }
            p{
                font-size: 18px;
                line-height: 1.3;
            }
        }
    }
    // Line Up Start
    .line-up-sec{
        padding: 30px 0 76px 0;
        background-size: contain;
        background: none;
        .current-fund-head{
            font-size: 30px;
            letter-spacing: -1px;
            line-height: 1;
            padding-bottom: 16px;
            margin-bottom: 0;
        }
        .accordion-titles{
            background: linear-gradient(to right, #f7f5ed 25%, #F1EBE1 25% 50%, #f7f5ed 50% 75%, #F1EBE1 75% 100%) ;
            padding: 0;

            .fund-name-class {
                display: none;
            }
            .col-lg-2 {
                width: 25%;
                margin-top: 5px;
                padding-left: 5px;

            }
            p{
                font-size: 13px;
                line-height: 1.3;
                flex-direction: column;
                .tooltip-cont {
                    order: -1;
                img{
                    &.fa-question-circle{
                        margin-left: 0px;
                        max-width: 13px;
                        max-height: 13px;
                    }
                }
                .tooltiptext {
                    left: 0px;
                    top: -22px;
                    font-size: 10px;
                    line-height: 1.2;
                    min-width: 100%;
                    max-width: 100%;
                    .tooltip-close {
                        right: 3px;
                        top: 1px;
                    }
                    .tooltip-close svg {
                        width: 7px;
                        height: 7px;
                    }
                }
            }
            }
        }
        .tab-sec-accordion{
            .accordion-item{
                    background: linear-gradient(
                    to right,
                    #ffffff00 25%,
                    #F1EBE1 25% 50%,
                    #ffffff00 50% 75%,
                    #F1EBE1 75% 100%
                );
                .tab-sec-accordion-header{
                    button{
                        &.accordion-button{
                            padding: 10px 0;
                            justify-content: center;
                            .tab-accordian_head{
                                padding-left: 0;
                                line-height: 1;
                                font-size: 13px;
                                margin-bottom: 10px;
                            }
                            h6{
                                line-height: 1;
                                font-size: 13px;
                                padding-left: 5px;
                                color: #726E68;
                                font-weight: 100;
                                margin-bottom: 10px;
                            }
                            &:not(.collapsed){
                                &::after{
                                    background-size: 8px;
                                }
                            }
                            &::after{
                                background-size: 8px;
                                width: 10px;
                                height: 16px;
                            }
                            .row {
                                width: 100%;
                                .custom-col-full {
                                    width: 100%;
                                    padding-left: 5px !important;
                                }
                                .custom-col-half {
                                    width: 25%;
                                    padding-left: 5px !important;
                                    h6{
                                        padding-left: 0 !important ;
                                    }
                                }
                            }
                        }
                    }
                }
                .accordion-collapse{
                    .accordion-body {
                        padding: 10px 0;
                        p{
                            width: 100% !important;
                            font-size: 12px;
                            line-height: 1.3;
                        }
                    }
                }
            }
        }
    }

    // How We Start
    .how-we-sec{
        padding: 0;
        .how-head-sec{
            h2{
                font-size: 30px;
                line-height: 1;
                margin-bottom: 27px;
                padding-bottom: 10px;
            }
            h5{
                font-size: 18px;
                line-height: 1;
                margin-bottom: 10px;
            }
            p{
                font-size: 18px;
                line-height: 1.3;
                max-width: 100%;
            }
        }
        .how-bottom-sec{
            margin-top: 47px;
            h5{
                font-size: 18px;
                line-height: 1;
                margin-bottom: 21px;
            }
            .how-boxes{
                .how-single-box{
                    border-radius: 20px;
                    padding: 25px;
                    min-height: 385px;
                    margin-bottom: 58px;
                    margin-right: 10px;
                    h5{
                        font-size: 20px;
                        line-height: 1;
                        margin-bottom: 11px;
                        &:after{
                            width: 14px;
                            height: 14px;
                            border-radius: 50px;
                        }
                    }
                    p{
                        font-size: 18px;
                        line-height: 1.3;
                    }
                }
                .slick-slider{
                    .slick-arrow{
                        position: absolute;
                        top: -14px;
                        width: auto;

                        &.slick-next{
                            right: 30px;
                            &::before{
                                content: "";
                                display: block;
                                width: 45px;
                                height: 20px;
                                margin: 0;
                                border: none;
                                transform: rotate(0deg) !important;
                                background-image: url('../../public/assets/images/arrows.png');
                                background-repeat: no-repeat;
                                background-size: contain;
                            }
                        }
                        &.slick-prev{
                            left: auto;
                            right: 75px;
                            display: none !important;
                            &::before{
                                content: "";
                                display: block;
                                width: 45px;
                                height: 20px;
                                border: none;
                                margin: 0;
                                transform: rotate(180deg) !important;
                                background-image: url('../../public/assets/images/arrows.png');
                                background-repeat: no-repeat;
                                background-size: contain;
                            }
                        }

                    }

                }
            }
        }

    }
    // Comparison Start
    .comparison-sec{
        .camparison_accordion{
            .comparison_pera{
                font-size: 30px;
                line-height: 1;
                margin-bottom: 10px;
                padding-bottom: 10px;
            }
            .comparison-table{
                .fund-name-class {
                    display: none;
                }
                .col-md-2 {
                    width: 33.33%;
                }
                p{
                    font-size: 15px;
                    line-height: 1.3;
                }
                .cost_head_div {
                    img{
                        margin-left: 10px;
                        max-width: 12px;
                        max-height: 12px;
                    }
                }
            }
            .accordion-item{
                .accordion-button{
                    .comparison_accordion_header {
                        line-height: 1;
                        font-size: 20px;
                        margin-top: 10px;
                    }
                    img{
                     display: none;
                    }
                }
                .accordion-collapse{
                    .accordion-body{
                       .col-md-4{
                        width: 100%;
                        h6{
                            font-size: 16px;
                            line-height: 1.4;
                            margin: 0;
                        }
                       }
                        .col-md-2{
                            width: 33.33%;


                        .mobile-grn-arrow {
                            margin-top: 20px;
                            margin-bottom: 10px;
                            display: block;
                        }
                        h6{
                            font-size: 20px;
                            line-height: 1.2;
                        }
                        .good-head {
                            color: #F1A83E;
                        }
                        .fossil-head {
                            color: #CF87E6;
                        }
                         .vote-head {
                            color: #7FD03E;
                        }
                        p{
                            font-size: 14px;
                            line-height: 1.3;
                        }

                       }
                    }
                }
            }
        }

    }


  // Fossil Free Start
.fossil-free-sec{
    .background-img{
    .inner-content{
    margin: 0;
    .container {
    max-width: 100%;
    .inner-content-img.col {
        max-width: 50%;
    }
    .inner-content-data.col {
        max-width: 40%;
        margin: 0;
    }
    }

    h5{
    font-size: 20px;
    line-height: 1.1;
    margin-bottom: 20px;
    }

    }
    }
    }
    // Graph Start
    .graph-sec{
        .improvedreturns{
            position: relative;
            h2{
                font-size: 20px;
                line-height: 1.2;
                max-width: 300px;
            }
            p{
                font-size: 16px;
                line-height: 1.3;
                max-width: 340px;
                margin-top: 10px;
            }
            .improved-label{
                label{
                    font-size: 15px;
                    line-height: 1;
                    &:before{
                        padding: 4px;
                        margin-right: 6px;
                        border: 1px solid #13166C;
                    }
                }
                #improvedreturns:checked + label{
                    &:after{
                        top: 0px;
                        left: 3px;
                        width: 6px;
                        height: 11px;
                        border-width: 0 2px 2px 0;
                    }
                }
            }
        }
    }
    // Affordable Simple Start
    .affordable-simple-sec{
        margin-top: 30px;
        margin-bottom: 30px;
        .affordable-col{
            padding-bottom: 21px;
            margin-bottom: 21px;
            border: none;
        }
        h2{}
        .improvedreturns{
            p{
                font-size: 16px;
                line-height: 1.3;
                max-width: 100%;
                margin-top: 10px;
                margin-bottom: 21px;
            }
            .improved-label{
                label{
                    font-size: 15px;
                    line-height: 1;
                    &:before{
                        padding: 4px;
                        margin-right: 6px;
                        border: 1px solid #13166C;
                    }
                }
                #affordable:checked + label{
                    &:after{
                        top: 0px;
                        left: 3px;
                        width: 6px;
                        height: 11px;
                        border-width: 0 2px 2px 0;
                    }
                }
                #simplestart:checked + label{
                    &:after{
                        top: 0px;
                        left: 3px;
                        width: 6px;
                        height: 11px;
                        border-width: 0 2px 2px 0;
                    }
                }
            }
        }
        .progress-sec{
            h5{
                font-size: 12px;
                line-height: 1;
                margin-bottom: 10px;
            }
            .single-progress{
                margin-bottom: 20px;
                p{
                    font-size: 12px;
                    line-height: 1;
                }
                .single-progress-bar{
                    margin-bottom: 5px;
                    h6{
                        font-size: 21px;
                        line-height: 1;
                    }
                    .counter-img-main{
                        margin-left: 12px;
                        .progress{
                            border-radius: 50px;
                            height: 2px;
                            .progress-bar{
                                height: 10px;
                                border-radius: 50px;
                            }
                        }
                    }
                }
            }
            .images-sec{
                img{
                    margin-right: 24px;
                    max-width: 150px;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
    }
    // Help Start
    .help-sec{
        .background-img{
            .inner-content{
                margin: 0;
                h5{
                    font-size: 30px;
                    line-height: 1.1;
                    margin-bottom: 15px;
                }
                p{
                    font-size: 16px;
                    line-height: 1.3;
                    max-width: 240px;
                }
                a{
                    line-height: 1;
                    padding: 10.5px 18.8px;
                    font-size: 18px;
                    border-radius: 50px;
                }
            }
        }
    }
    // FAQs Start
    .faq_sec {
        .faq_head {
            font-size: 30px;
            line-height: 1.3;
            padding-bottom: 0;
            margin-bottom: 26px;
        }
        .accordion_main{
            .accordion_item{
                .accordion_header{
                    .accordion-button{
                        padding: 30px 0px !important;
                        font-size: 20px;
                    }
                }
            }
            .accordion-body{
                font-size: 18px;
                line-height: 1.3;
                width: 80%;
                padding-top: 0;
                padding-bottom: 30px;
            }
        }
    }
    // Footer
    .footer{
        padding: 30px 0;
        .top-sec{
            padding-bottom: 20px;
            margin-bottom: 20px;
            .mid-sec{
                ul{
                    justify-content: space-between;
                    li{
                        h4{
                            font-size: 14px;
                            line-height: 1;
                            margin-bottom: 5px;
                        }
                        ul{
                            li{
                                line-height: 1.4;
                                a{
                                    font-size: 12px;
                                    line-height: 1;
                                }
                            }
                        }
                    }
                }
            }
            .right-sec{
                p {
                    font-size: 12px;
                    line-height: 1.3;
                    margin-bottom: 10px;
                }
                a {
                    font-size: 12px;
                    line-height: 1;
                    margin-bottom: 10px;
                }
            }
        }
        .bottom-sec{
            p{
                line-height: 1.3;
                font-size: 12px;
            }
        }
    }
}
@media (max-width:991px) {
    // Counter Box Start
    .hero-boxes{
        .counter-box-col {
            max-width: 48%;
            width: 48%;
            flex: auto;
            margin: 1%;
            padding: 0;
        }
    }
    // How We Start
    .how-we-sec{
        .how-bottom-sec{
            .how-boxes{
                .how-single-box{
                    min-height: 355px;
                }
            }
        }
    }
    // Fossil Free Start
    // .fossil-free-sec{
    //     .background-img{
    //         .inner-content{
    //             margin: 0 0 0 130px;
    //         }
    //     }
    // }
    // Help Start
    // .help-sec{
    //     .background-img{
    //         .inner-content{
    //             margin: 0 0 0 130px;
    //         }
    //     }
    // }
    // Footer
    .footer{
        .top-sec{
            .left-sec {
                margin: 0 0 30px;
            }
            .mid-sec{
                max-width: 560px;
                float: left;
            }
            .right-sec{
                width: 160px;
            }
        }
    }

}

@media (max-width:767px) {
    // Hero Start
    .home-hero-sec{
        padding-top:30px;
        .container{
            padding: 0;

        .top-hero-sec{
            padding-left: 30px;
            padding-right: 30px;
            .change-btn{
                font-size: 15px;
                margin-bottom: 20px;
                img{
                    margin-right: 3px;
                }
            }
            h2{
                max-width: 100%;
                margin-bottom: 20px;
            }
            p{
                line-height: 1.3;
            }
        }
        .public-stats-left-main{
            padding-left: 30px;
            padding-right: 30px;
            img{
                max-width: 100%;
                display: none;
            }
            .public-stats-head{
                max-width: 100%;

            }
        }
    }

    }
    // Counter Box Start
    .hero-boxes{
        padding-left: 30px;
        padding-right: 30px;
        .counter-box-col {

            padding: 5px;
        }
    }
    // Counter Start
    .counter-box{
        .counter-img-main{
            .progress {
                margin: 0 0 15px;
                .progress-bar{
                    height: 10px;
                }
            }
        }
    }
    // Compared Other Start
    .compared-other-sec{
        padding-left: 30px;
        padding-right: 30px;
        .public-stats-main {

            .public-stats-logos{
                ul{
                    display: none;
                }
            }
            .public-stats-progress-main{
                .public-stats-progress{
                    margin: 0 0 40px;
                    display: block;
                    height: auto;
                    span{
                        display: block;
                        margin:0 0 10px;
                        &:last-child{
                            padding-left: 0;
                            display: block;
                            margin-top: 20px;
                            font-size: 14px;
                        }
                    }
                    .progress{
                        .progress-bar{
                            height: 20px;
                        }
                    }
                }
            }

            .public-stats-right-progress {
                .progress {
                    height: 25px;
                    .progress-bar{
                        height: 25px;
                    }

                }
            }
        }
        .container{
            padding: 0;
        }
    }
    //Slider Start{
    .slider-sec{
        padding-left: 30px;
        .single-slide{
            .top_btn{
                h3{
                    line-height: 1.2;
                }
                a{
                    line-height: 1;
                }
            }
            .inner-content{
                padding: 0 15px;
                h5{

                    span{
                        max-width: 60%;
                    }
                }
            }
            .equation-content{
                max-width: 90%;
                .total-cal {
                    p {
                        min-width: 16%;
                    }
                }
            }
        }
        .container{padding: 0;}

    }
    // Home Content Start{
    .home-content-sec{
        .container{
            padding-left: 30px;
            padding-right: 30px;
        .inner-content{
            max-width: 100%;
        }
    }
    }
    // Line Up Start
    .line-up-sec{
        .container{
            padding-left: 30px;
            padding-right: 30px;
        .tab-sec-accordion{
            .accordion-item{
                .tab-sec-accordion-header{
                    button{
                        &.accordion-button{
                            h6{
                                padding-left: 0;

                            }

                        }
                    }
                }
                .accordion-collapse{
                    .accordion-body {
                        padding: 15px 0;
                        p{
                            width: 100% !important;
                            font-size: 12px;
                            line-height: 1.3;
                        }
                    }
                }
                .row {
                    margin-left: inherit;
                    width: 100%;
                    .custom-col-half {
                        width: 25%;
                    }
                    .col-md-6 {
                        padding-right: calc(var(--bs-gutter-x) * 0.5);
                        padding-left: calc(var(--bs-gutter-x) * 0.5);
                    }
                }
            }
        }
        .accordion-titles {
            margin: 0;
        }
    }
    }

    .how-we-sec {
        .container{
              padding:0px;
              .col-lg-12{
                padding:0px;
                .how-head-sec{
                    padding-left: 30px;
                    padding-right: 30px;
                }
                .how-bottom-sec{
                    h5{
                        padding-left: 30px;
                        padding-right: 30px;
                    }
                    .how-boxes{
                        padding-left: 30px;
                        h5{
                            padding: 0;
                        }
                    }
                }
              }
        }
  }
    // Fossil Start
    .fossil-free-sec {
        margin-top: 55px;
        .background-img{
            background-image:  url("../../public/assets/images/mobile-testimonial-bg.png");
            .slick-slide {
                overflow: hidden;
                height: 100%;
                max-height: 660px;

            }
            .slick-arrow{
                position: absolute;
                top: -15px;
                width: auto;

                &.slick-next{
                    right: 30px;
                    &::before{
                        width: 45px;
                        height: 20px;
                        margin: 0;
                        border: none;
                        transform: rotate(0deg) !important;
                        background-image: url('../../public/assets/images/arrows.png');
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }
                &.slick-prev{
                    left: auto;
                    right: 75px;
                    display: none !important;
                    &::before{
                        width: 45px;
                        height: 20px;
                        border: none;
                        margin: 0;
                        transform: rotate(180deg) !important;
                        background-image: url('../../public/assets/images/arrows.png');
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }

            }

            .inner-content {
                position: relative;
                max-width: 100%;

                .container {
                    .innner-content-row.row {
                        flex-direction: column;
                        .inner-content-data.col {
                            max-width: 100%;
                            order: -1;
                            padding-top: 57px;
                            padding-left: 40px;
                            padding-right: 40px;
                            padding-bottom: 261px;
                            h5 {
                                font-size: 25px;
                                min-height: 250px;
                            }
                            p{


                                span.testmonial-title {
                                    font-size: 20px;
                                    line-height: 1.5;
                                }

                                span.testmonial-para {
                                    font-size: 14px;
                                    line-height: 1.3;
                                }

                            }

                        }
                        .inner-content-img.col {
                            max-width: 100%;
                            position: absolute;
                            bottom: -25px;
                            right: -120px;
                        }
                    }
                }

            }
        }

    }
    // Help Start
    .help-sec {
        margin-top: 30px;
        .background-img{
            img {
                display: none;
            }
            .inner-content {
                margin: 55px 0 34px 0;
                position: relative;
                max-width: 100%;
                top: 0px;
                left: 0;
                transform: unset;
                padding-top: 44px;
                padding-bottom: 30px;
                padding-left: 30px;
                align-items: flex-start;
                background-image:  url("../../public/assets/images/testtimonial-2.png");
                background-repeat: no-repeat;
                background-size: cover;
                min-height: 546px;
                .container {
                    max-width: 367px;
                }
            }
        }
    }
    // Graph Start
    .graph-sec{
        .container {
            padding-left: 30px;
            padding-right: 30px;
        .col-lg-12{
            padding: 0;
        .improvedreturns{
            position: relative;
        }
    }
    }
    }

    // Affordable Start
    .affordable-simple-sec{
    .container {
        padding-left: 30px;
        padding-right: 30px;
    }
}

    // FAQs Start{
    .faq_sec{
        .container{
            padding-left: 30px;
            padding-right: 30px;

        margin-top: 35px;
        .accordion_main{
            .accordion-body{
                width: 90%;
                padding-right: 0;
            }
            .accordion-item{
                .accordion_header{
                    .accordion-button{
                        line-height: 1.4;
                    }
                }
            }
        }
    }
    }
    // Footer Start
    .footer {
        .d-xs-none{
            display: none;
        }
        .mobile-mid-sec {
            display: block;
            ul{
                display: block;
                list-style: none;
                padding-left: 0;
                li{
                    width: 50%;
                    float: left;
                    margin-top: 20px;
                    h4{
                        color: #DDD2C1;
                        font-size: 16px;
                    }
                    &:last-child{
                        ul{
                            li{
                                a{
                                    margin: 15px 0;
                                    display: block;
                                }
                            }
                        }
                    }
                    ul{
                        li{
                            width: 100%;
                            float: none;
                            margin-top: 0;
                            a{
                                color: #DDD2C1;
                                opacity: 0.75;
                                text-decoration: none;
                                font-size: 14px;
                            }
                            p{
                                color: #DDD2C1;
                                opacity: 0.75;
                                text-decoration: none;
                                font-size: 14px;
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    // Comparison Start
    .comparison-sec{
        .container{
            padding-left: 30px;
            padding-right: 30px;
        .col-lg-12{
            padding: 0  ;
        .camparison_accordion{
            .accordion-item{
                .accordion-button{
                    padding: 5px 0;
                }
                .accordion-collapse{
                    .accordion-body{
                        padding-top: 0px;
                    }
                }
            }
        }
    }
    }
    }

}



// HomePage Styling
@media (max-width: 1024px) {

    .accordion-titles.row{
        top: 85px;

}
    .row {
        --bs-gutter-x: unset;
        }

	.home-hero-mid-txt-cont-ls {
		p {
			font-size: 30px;
		}
	}
	.home-hero-mid-txt-cont-rs {
		p {
			font-size: 17px;
		}
		max-width: 60%;
	}
	p.sample-retirement-plans-cont-p {
		font-size: 18px;
		margin-top: 30px;
		margin-bottom: 20px;
	}
	.sample-retirement-plans-cont-data-item {
		min-height: 160px;
		padding: 0;
		justify-content: center;
		a {
			img {
				max-height: 50px;
			}
		}
	}
	.sample-retirement-plans-cont-data-item-text {
		p {
			font-size: 14px;
			line-height: 1;
			margin-top: 15px;
		}
	}
}
@media (max-width:767px) {
	.home-hero-mid-txt-cont-rs {
		max-width: 100%;
        margin: 0;
	}
	.sample-retirement-plans-cont-data-item {
		min-height: 150px;
		padding: 0;
		width: 48.5%;
		margin-bottom: 15px;
		a {
			img {
				max-height: 50px;
			}
		}
	}
	.home-hero-mid-txt-cont {
		flex-direction: column;
		padding-top: 50px;
	}
	.home-hero-mid-txt-cont-ls {
		p {
			br {
				display: none;
			}
		}
	}
	p.sample-retirement-plans-cont-p {
		margin-top: 40px;
		margin-bottom: 30px;
	}
	.home-hero-mid-txt-sec {
		margin-left: 10px;
		margin-right: 10px;
	}
	.sample-retirement-plans-sec.row {
		margin-left: 10px;
		margin-right: 10px;
	}
    .email-wall {
        padding-left: 30px;
        padding-right: 30px;
    }
}


.compared-other-sec .public-stats-main .public-stats-right-progress .progress .progress-bar-sec .progress-bar[style="width: 0%;"] {
    background: transparent;
}

.mobile-hide {
    display: none;
}
.desktop-hide {
    display: block;
}
@media (max-width: 1024px){
    .mobile-hide {
    display: block;
}
.desktop-hide {
    display: none;
}
}



@media (max-width: 425px){
    .line-up-sec {
        .tab-sec-accordion {
            .accordion-item {
                .tab-sec-accordion-header {
                    button.accordion-button {
                        .tab-accordian_head {
                            font-size: 3vw;
                        }
                        h6 {
                            font-size: 3vw;
                        }
                        .h6 {
                            font-size: 3vw;
                        }
                    }
                }
            }
        }
        .accordion-titles {
            p {
                font-size: 3vw;
                word-break: break-word;
            }
        }
    }
    .home-hero-sec {
        .public-stats-left-main {
            .public-stats-head {
                font-size: 90px;
            }
        }
    }
    .email-wall {
        form {
            width: 350px;
        }
    }

}

@media (max-width: 380px){
    .slider-sec {
        .single-slide {
            .inner-content {
                h5 {
                    font-size: 55px;
                }
                .h5 {
                    font-size: 55px;
                }
            }
        }
    }
    .hero-boxes {
        .counter-box {
            .counter-box-para {
                font-size: 16px;
            }
        }
    }

    .home-hero-sec {
        .public-stats-left-main {
            .public-stats-head {
                font-size: 80px;
            }
        }
    }

    .email-wall {
        form {
            width: 280px;
        }
    }
}

@media (max-width: 325px){
    .home-hero-sec {
        .public-stats-left-main {
        .public-stats-head {
        font-size: 75px;
        }
        }
        }
        .hero-boxes {
        padding-left: 0px;
        padding-right: 0px;
        }
        .compared-other-sec {
        padding-left: 10px;
        padding-right: 10px;
        }
        .slider-sec {
        padding-left: 10px;
        }
        .home-content-sec {
        .container {
        padding-left: 10px;
        padding-right: 10px;
        }
        }
        .line-up-sec {
        .container {
        padding-left: 10px;
        padding-right: 10px;
        }
        }
        .how-we-sec {
        .container {
        .col-lg-12 {
        .how-head-sec {
        padding-left: 10px;
        padding-right: 10px;
        }
        .how-bottom-sec {
        h5 {
        padding-left: 10px;
        padding-right: 10px;
        }
        .h5 {
        padding-left: 10px;
        padding-right: 10px;
        }
        .how-boxes {
        padding-left: 10px;
        }
        }
        }
        }
        }
        .comparison-sec {
        .container {
        padding-left: 10px;
        padding-right: 10px;
        }
        }
        .fossil-free-sec {
        .background-img {
        .inner-content {
        padding-left: 10px;
        }
        }
        }
        .graph-sec {
        .container {
        padding-left: 10px;
        padding-right: 10px;
        }
        }
        .affordable-simple-sec {
        .container {
        padding-left: 10px;
        padding-right: 10px;
        }
        }
        .help-sec {
        .background-img {
        .inner-content {
        padding-left: 10px;
        }
        }
        }
        .faq_sec {
        .container {
        padding-left: 10px;
        padding-right: 10px;
        }
        }
        .comparison-sec {
            .camparison_accordion {
            .accordion-item {
            .accordion-collapse {
            .accordion-body {
            .col-md-2 {
            h6 {
            font-size: 15px;
            }
            .h6 {
            font-size: 15px;
            }
            }
            }
            }
            }
            }
            }
            .comparison-sec {
                .camparison_accordion {
                .accordion-item {
                .accordion-collapse {
                .accordion-body {
                .col-md-2 {
                p {
                font-size: 12px;
                }
                }
                }
                }
                }
                }
                }

                .fossil-free-sec {
                    .inner-content-img.col {
                        right: -100px !important;
                        bottom: 0 !important;
                    }
                }

}

@media (min-width:768px) and (max-width:1024px) {
    .accordion-titles.row {
        top: 110px;
    }
}

.navbar-light .navbar-toggler-icon {
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27%2313166C%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e');
}
